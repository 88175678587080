<template>
    <component
        :is="headingTag"
        :class="[
            'container grid grid-cols-1',
            classes.textAlignment[textAlignment],
            classes.contentGapHalf[globals.styles.spacing.value],
            classes.contentMarginBottomDouble[globals.styles.spacing.value],
        ]"
    >
        <component
            :is="titleTag"
            v-if="title"
            :class="[
                'font-title text-3xl',
                classes.fontWeight[globals.fonts?.title_font_weight] || 'font-normal',
                classes.textTransform[globals.fonts?.title_text_transform] || 'normal-case',
                classes.letterSpacing[globals.fonts?.title_letter_spacing] || 'tracking-normal',
                `text-tenant-${titleColor}`,
            ]"
        >
            {{ title }}
        </component>
        <component
            :is="subtitleTag"
            v-if="subtitle"
            :class="[
                'font-subtitle text-xl sm:text-2xl',
                classes.fontWeight[globals.fonts?.subtitle_font_weight] || 'font-normal',
                classes.textTransform[globals.fonts?.subtitle_text_transform] || 'normal-case',
                classes.letterSpacing[globals.fonts?.subtitle_letter_spacing] || 'tracking-normal',
                `text-tenant-${subtitleColor}`,
            ]"
        >
            {{ subtitle }}
        </component>
    </component>
</template>

<script setup lang="ts">
    import { useSite } from '@/site/composables/use-site.ts';

    const props = withDefaults(defineProps<{
        textAlignment?: string;
        title?: string;
        subtitle?: string;
        titleTag?: string;
        titleColor?: string;
        subtitleTag?: string;
        subtitleColor?: string;
        headingTag?: string;
        overlayColor?: string;
    }>(), {
        textAlignment: 'center',
        titleTag: 'h3',
        subtitleTag: 'h4',
        headingTag: 'header',
    });

    const titleColor = computed(() => {
        return props.overlayColor === 'darken' ? 'white' : 'black';
    });

    const subtitleColor = computed(() => {
        return props.overlayColor === 'darken' ? 'white' : 'black';
    });

    const { classes, globals } = useSite();
</script>

